import Breadcrumb from "@/components/Common/Breadcrumb";
import SEO from "@/components/seo";
import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import News from "@/components/News";
import Partners from "@/components/Home/Partners";
import BlogLayout from "@/components/Layout/BlogLayout";
import Layout from "@/components/Layout";
import { useBlog } from "@/context/BlogContext";

const NewsP = () => {
  const { clearStorage } = useBlog();

  useEffect(() => {
    ["author", "category", "tag", "search"].forEach((el) => {
      clearStorage(el);
    });
  }, []);

  return (
    <Layout>
      <SEO title="News" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Latest Posts" pathName="News" />
      <BlogLayout>
        <News />
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default NewsP;
